<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>日志详情</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">操作日志</a></li>
        <li>详情</li>
      </ol>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>日志详情</h3>-->
<!--      </div>-->
      <div class="box-body">
        <table class="view">
          <tr>
            <th>编号：</th>
            <td>{{item.id}}</td>
            <th>操作人员：</th>
            <td>{{item.staff ? item.staff.name : item.staff_id}}</td>
          </tr>
          <tr>
            <th>操作内容：</th>
            <td>{{item.content}}</td>
          <th>操作对象：</th>
          <td>{{item.record_ids.join(",")}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box">
      <div class="box-head">
        <h3>详细信息</h3>
      </div>
      <div class="box-body">
      <table class="view">
          <tr>
            <th>操作人员：</th>
            <td>{{item.staff ? item.staff.name : item.staff_id}}</td>
            <th></th>
            <td></td>
          </tr>
        <tr>
          <th>操作内容：</th>
          <td>{{item.content}}</td>
          <th>操作对象：</th>
          <td>{{item.record_ids.join(",")}}</td>
        </tr>
        <tr>
          <th>操作参数：</th>
          <td colspan="3">{{item.params}}</td>
        </tr>
        <tr>
          <th>令牌：</th>
          <td>{{item.token}}</td>
          <th>IP地址：</th>
          <td>{{item.ip}}</td>
        </tr>
        <tr>
          <th>代理：</th>
          <td colspan="3">{{item.agent}}</td>
        </tr>
        <tr>
          <th>修改时间：</th>
          <td>{{item.changed.format('yyyy-MM-dd hh:mm:ss')}}</td>
          <th>操作时间：</th>
          <td>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</td>
        </tr>
      </table>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'SysOplogDetail',
  data() {
    return {
      item: {
        staff: {}, record_ids: [],
        changed: new Date(), created: new Date()
      }
    }
  },
  mounted() {
    this.request();
  },

  methods: {
    request() {
      let id = this.$route.params.id;
      this.$get('/sys/oplog/detail/' + id).then(res => {
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          this.$util.timeFields(ajax.data.item);
          this.item = ajax.data.item;
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>